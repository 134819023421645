<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-alert
      class="alert-fixed fixed-top rounded-1 m-4 bg-light alert-front"
      :show="dismissCountDown"
      variant="success"
      dismissible
      @dismissed="dismissCountDown = 0"
      @dismiss-count-down="countDownChanged"
    >
      <p class="float-end">{{ dismissCountDown }} วินาที...</p>
      <p>แจ้งเตือน</p>
      <hr />
      <p>{{ resalert }}</p>
      <!-- <b-progress
        variant="warning"
        :max="dismissSecs"
        :value="dismissCountDown"
        height="4px"
      ></b-progress> -->
    </b-alert>
    <div class="row">
      <b-overlay
        :show="overlayFlag"
        :spinner-variant="this.$overlayVariant"
        :spinner-type="this.$overlayType"
        :rounded="this.$overlayRounded"
      >
        <div class="col-lg-12">
          <div class="card">
            <!-- <form class="needs-validation" @submit.prevent="Form"> -->
            <div class="card-body">
              <div class="row mt-2">
                <div class="row">
                  <div class="col-md-6">
                    <p class="card-title-desc">รายละเอียด</p>
                  </div>
                  <div class="col-md-6 text-end">
                    <span
                      class="badge bg-warning text-dark font-size-12"
                      v-if="this.status == 'DFT'"
                      >ร่าง</span
                    >
                    <span
                      class="badge bg-danger font-size-12"
                      v-if="this.status == 'NAPV'"
                      >ไม่ผ่าน</span
                    >
                    <span
                      class="badge bg-success font-size-12"
                      v-if="this.status == 'APV'"
                      >ผ่าน</span
                    >
                    <span
                      class="badge bg-info font-size-12 ms-2"
                      v-if="this.status === 'SUBM'"
                    >
                      รอตรวจสอบ
                    </span>
                  </div>
                </div>
                <!-- <div class="row">
                  <div class="col-md-12 col-sm-12">
                    <div class="row align-items-center">
                      <div class="col-md-9 col-sm-9">
                        <div class="mb-3 position-relative">
                         <label for="nameTh">การขาย</label>:
                          <b-form-input
                            v-model="saleCode"
                            type="search"
                            placeholder=""
                            class="form-control ms-2"
                            v-b-modal.modalSale
                          ></b-form-input>
                        </div>
                      </div>
                      <div class="col-md-3 col-sm-3 col-3">
                        <div class="mt-2 position-relative">
                          <b-button
                            class="btn"
                            variant="info"
                            v-b-modal.modalSale
                          >
                            <i class="uil-file-search-alt"></i
                          ></b-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->
                <div class="col-md-4 col-sm-4">
                  <div class="mb-3 position-relative">
                    <label class="ms-2" for="nameTh">เลขที่ใบขาย</label>:
                    <b-form-input
                      disabled
                      v-model="saleCode"
                      placeholder=""
                      class="form-control ms-2"
                    ></b-form-input>
                  </div>
                </div>
                <div class="col-sm-4 col-md-4">
                  <div class="mb-3 position-relative">
                    <label class="ms-2" for="nameTh"> สาขา:</label>
                    <b-form-input
                      disabled
                      v-model="filter.branchId"
                      placeholder=""
                      class="form-control ms-2"
                    ></b-form-input>
                  </div>
                </div>

                <div class="col-md-4 col-sm-4">
                  <div class="mb-3 position-relative">
                    <label class="ms-2" for="nameTh">ชื่อ-นามสกุลลูกค้า</label>:
                    <b-form-input
                      disabled
                      v-model="customerNameTh"
                      placeholder=""
                      class="form-control ms-2"
                    ></b-form-input>
                  </div>
                </div>
                <div class="col-md-4 col-sm-6">
                  <div class="mb-3 position-relative">
                    <label class="ms-2" for="nameTh">ผู้สร้าง:</label>
                    <b-form-input
                      disabled
                      v-model="createdBy"
                      placeholder=""
                      class="form-control ms-2"
                    ></b-form-input>
                  </div>
                </div>
                <div class="col-md-5 col-sm-6">
                  <div class="mb-3 position-relative">
                    <label class="ms-2" for="nameTh">สินค้า</label>:
                    <b-form-input
                      disabled
                      v-model="productNameTh"
                      placeholder=""
                      class="form-control ms-2"
                    ></b-form-input>
                  </div>
                </div>

                <div class="col-md-3 col-sm-6">
                  <div class="mb-3 position-relative">
                    <label class="ms-2" for="nameTh">เลขตัวถัง</label>:
                    <b-form-input
                      disabled
                      v-model="vin"
                      placeholder=""
                      class="form-control ms-2"
                    ></b-form-input>
                  </div>
                </div>
              </div>
            </div>

            <!-- </form> -->
            <b-modal
              ref="modalSale"
              id="modalSale"
              title="รายการการขาย"
              hide-footer
              size="xl"
              centered
            >
              <div class="card-body">
                <div class="row">
                  <div class="col-sm-12 col-md-12 align-items-center">
                    <div class="row align-items-center">
                      <div class="col-sm-4 col-md-4">
                        <div class="mb-3 position-relative">
                          <label class="d-inline align-items-center">
                            สาขา:</label
                          >
                          <multiselect
                            v-model="filter.branchId"
                            :options="localData"
                            label="nameTh"
                            :show-labels="false"
                          >
                          </multiselect>
                        </div>
                      </div>
                      <!-- <div class="col-sm-4 col-md-4">
                      <label class="d-inline align-items-center m-2">
                        ชื่อลูกค้า:
                        <b-form-input
                          v-model="filter.nameThPros"
                          type="search"
                          placeholder="ชื่อ-นามสกุล"
                          class="form-control ms-2"
                          @keyup.enter="handleSearchPros"
                        ></b-form-input>
                      </label>
                    </div>
                    <div class="col-sm-4 col-md-4">
                      <label class="d-inline align-items-center m-2">
                        นามสกุลลูกค้า:
                        <b-form-input
                          v-model="filter.familyNameThPros"
                          type="search"
                          placeholder="นามสกุลลูกค้า"
                          class="form-control ms-2"
                          @keyup.enter="handleSearchPros"
                        ></b-form-input>
                      </label>
                    </div> -->
                      <div
                        class="col-2 col-sm-2 col-md-2 text-end"
                        style="margin-left: auto; margin-right: 0"
                      >
                        <b-button
                          class="btn btn ms-2"
                          variant="info"
                          type="submit"
                          @click="handleSearch"
                        >
                          <i class="uil-search"></i>
                          ค้นหา
                        </b-button>
                      </div>
                    </div>
                  </div>
                </div>
                <b-skeleton-wrapper :loading="loading">
                  <template #loading>
                    <b-skeleton-table
                      :rows="5"
                      :columns="6"
                      :table-props="{ bordered: false, striped: true }"
                      animation="throb"
                    ></b-skeleton-table>
                  </template>
                  <div class="row mt-2">
                    <div class="col-sm-12 col-md-12">
                      <div class="row align-items-center">
                        <div class="col-sm-5 col-md-3">
                          <div
                            id="tickets-table_length"
                            class="dataTables_length"
                          >
                            <label class="d-inline-block align-items-center">
                              แสดงผล
                              <b-form-select
                                v-model="perPage"
                                size="sm"
                                :options="pageOptions"
                                @input="handlePageChange"
                              ></b-form-select
                              >&nbsp; รายการ
                            </label>
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-6"></div>
                      </div>
                    </div>
                  </div>

                  <div class="table-responsive mb-0">
                    <b-table
                      :items="rowData"
                      :fields="fields"
                      responsive="true"
                      :per-page="perPage"
                      :current-page="1"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :filter="filter"
                      :filter-included-fields="filterOn"
                      ref="selectableTable"
                      selectable
                      :select-mode="selectMode"
                      @row-selected="select"
                    >
                      <template #cell(saleCondition)="rowData">
                        <span>
                          {{ checksaleCondition(rowData.item.saleCondition) }}
                        </span>
                      </template>
                      <template
                        #cell(index)="rowData"
                        v-if="this.currentPage > 1"
                      >
                        {{
                          rowData.index + 1 + (currentPage * perPage - perPage)
                        }}
                      </template>
                      <template #cell(index)="rowData" v-else>
                        {{ rowData.index + 1 }}
                      </template>
                      <template #cell(status)="rowData">
                        <span
                          class="badge bg-warning font-size-12 ms-2"
                          v-if="rowData.item.status === 'DFT'"
                        >
                          ร่าง
                        </span>

                        <span
                          class="badge bg-success font-size-12 ms-2"
                          v-if="rowData.item.status === 'APV'"
                        >
                          เสร็จสิ้น
                        </span>
                        <span
                          class="badge bg-danger font-size-12 ms-2"
                          v-if="rowData.item.status === 'L'"
                        >
                          Lost
                        </span>
                      </template>
                    </b-table>
                  </div>

                  <div class="row">
                    <span
                      v-if="this.totalRecord === 0"
                      class="text-center font-size-16"
                      >ไม่พบรายการ</span
                    >
                    <div class="col" v-if="this.totalRecord > 0">
                      <div class="col">
                        หน้า {{ currentPage }} จาก {{ this.totalPage }} ทั้งหมด
                        {{ totalRecord }} รายการ
                      </div>

                      <div class="col">
                        <div
                          class="
                            dataTables_paginate
                            paging_simple_numbers
                            float-end
                          "
                        >
                          <ul class="pagination pagination-rounded mb-0">
                            <b-pagination
                              v-model="currentPage"
                              :total-rows="totalRecord"
                              :per-page="perPage"
                              @change="handleChangePage"
                            ></b-pagination>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <br />
                    <hr />
                    <br />
                  </div>
                </b-skeleton-wrapper>
              </div>

              <div class="row">
                <div class="col-md">
                  <button class="btn btn-success float-end">ตกลง</button>
                </div>
              </div>
            </b-modal>
            <hr />
          </div>
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-bordered">
                  <tbody v-for="(item, index) in detail" :key="index">
                    <tr class="table-active">
                      <th colspan="4">{{ item.subjectNameTh }}</th>
                    </tr>
                    <tr v-for="(ele, index) in item.rules" :key="index">
                      <td colspan="4" class="p-0">
                        <table class="table table-bordered mb-0">
                          <tr>
                            <th colspan="4">
                              <div class="p-2">
                                {{ index + 1 }}. {{ ele.ruleNameTh
                                }}{{ checkData(ele.ruleNameEn) }}
                              </div>
                            </th>
                          </tr>

                          <tbody>
                            <tr>
                              <td class="text-center">
                                <div
                                  @click="showImgAudit(ele.rulefileId)"
                                  v-if="ele.rulethumbnailPath"
                                >
                                  <img
                                    :src="ele.rulethumbnailPath"
                                    class="preview-img"
                                    @click="singleImage = true"
                                  />
                                  <p>{{ checkPath(ele.rulethumbnailPath) }}</p>
                                </div>

                                <vue-easy-lightbox
                                  :visible="singleImage"
                                  :imgs="imagePath"
                                  @hide="singleImage = false"
                                ></vue-easy-lightbox>
                              </td>
                              <td class="text-center float-center">
                                <div
                                  @click="showImg(ele.fileId)"
                                  v-if="ele.thumbnailPath"
                                >
                                  <img
                                    :src="ele.thumbnailPath"
                                    class="preview-img"
                                    @click="singleImage = true"
                                  />
                                </div>
                                <vue-easy-lightbox
                                  :visible="singleImage"
                                  :imgs="imagePath"
                                  @hide="singleImage = false"
                                ></vue-easy-lightbox
                                ><br />
                                <span>{{ checkImg(ele.uploadFileBy) }}</span
                                ><br />
                                <span>{{ checkApproAt(ele.uploadAt) }}</span>
                                <br />
                                <b-button
                                  :disabled="
                                    status !== 'SUBM' ||
                                    ele.status == 'NAPV' ||
                                    ele.status == 'APV'
                                  "
                                  @click="showModal(ele)"
                                  class="font-size-13 ms-2 mb-3"
                                  variant="primary"
                                  size="sm"
                                  >อัพโหลดรูป</b-button
                                >
                              </td>
                              <td class="text-center">
                                <label for="">หมายเหตุ</label>
                                <textarea
                                  disabled
                                  v-model="ele.note"
                                  class="form-control"
                                  rows="4"
                                />
                                <br />
                                <span>{{ checkNote(ele) }}</span
                                ><br />
                                <span>{{ checkDate(ele) }}</span>
                              </td>
                              <td class="text-center">
                                <label for="" class="">ผลการตรวจสอบ</label>
                                <textarea
                                  :disabled="
                                    status !== 'SUBM' ||
                                    ele.status == 'NAPV' ||
                                    ele.status == 'APV'
                                  "
                                  v-model="ele.auditNote"
                                  class="form-control"
                                  rows="4"
                                  @change="putRule(ele)"
                                />

                                <br />
                                <span>{{ checkAppro(ele.approvedBy) }} </span
                                ><br />
                                <span>{{ checkApproAt(ele.approvedAt) }}</span>
                                <br />

                                <div
                                  class="row justify-content-center"
                                  v-if="
                                    ele.status == 'DFT' || ele.status == 'WIP'
                                  "
                                >
                                  <div
                                    class="col-md-3 col-sm-6 text-center mb-3"
                                  >
                                    <b-button
                                      :disabled="status !== 'SUBM'"
                                      @click="approveDetail(ele, 'APV')"
                                      class="font-size-13"
                                      variant="success"
                                      size="sm"
                                      >ผ่าน</b-button
                                    >
                                  </div>

                                  <div
                                    class="col-md-4 col-sm-6 text-center mb-3"
                                  >
                                    <b-button
                                      :disabled="status !== 'SUBM'"
                                      @click="approveDetail(ele, 'NAPV')"
                                      class="font-size-13"
                                      variant="danger"
                                      size="sm"
                                      >ไม่ผ่าน</b-button
                                    >
                                  </div>
                                </div>
                                <div
                                  class="row justify-content-center mb-2"
                                  v-else
                                >
                                  <span
                                    class="badge bg-danger font-size-12"
                                    v-if="ele.status == 'NAPV'"
                                    >ไม่ผ่าน</span
                                  >
                                  <span
                                    class="badge bg-success font-size-12"
                                    v-if="ele.status == 'APV'"
                                    >ผ่านแล้ว</span
                                  >
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row justify-content-end">
                <div class="col-md-3 col-sm-4 col-12">
                  <div class="mb-3 position-relative">
                    <code>* </code><label for="status">สถานะ:</label>
                    <multiselect
                      :disabled="this.status !== 'SUBM'"
                      v-model="formApv.status"
                      :options="statusOption"
                      label="nameTh"
                      track-by="id"
                      :show-labels="false"
                      :class="{
                        'is-invalid': submitApv && $v.formApv.status.$error,
                      }"
                    >
                    </multiselect>
                    <div
                      v-if="submitApv && $v.formApv.status.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.formApv.status.required">{{
                        error
                      }}</span>
                    </div>
                  </div>
                </div>

                <div class="text-end col-md-2 col-sm-4 col-12 mt-4">
                  <label for=""></label>
                  <button
                    :disabled="status !== 'SUBM'"
                    type="submit"
                    class="btn btn-success"
                    @click="FormSubmitAudit()"
                  >
                    บันทึกการตรวจสอบ
                  </button>
                  &nbsp;
                </div>
              </div>
            </div>
          </div>
          <b-modal
            ref="modalUploadImg"
            :id="`modalUploadImg-${auditDetailId}`"
            :title="`อัพโหลดรูป - ${this.ruleNameTh}`"
            hide-footer
            size="l"
            centered
          >
            <div class="card-body">
              <div class="row">
                <div class="col-md-9 col-sm-12 col-9">
                  <div class="position-relative">
                    <b-form-file
                      type="file"
                      ref="img"
                      id="img"
                      v-model="image"
                      accept="image/*"
                      class="form-control"
                      plain
                      @input="uploadRule(auditDetailId)"
                    ></b-form-file>
                  </div>
                </div>
              </div>
            </div>
          </b-modal>
        </div>
      </b-overlay>
    </div>
  </Layout>
</template>

<script>
import {
  required,

  // url,
  // alphaNum,
} from "vuelidate/lib/validators";

import Swal from "sweetalert2";
import useNetw from "@useNetw";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
// import DatePicker from "vue2-datepicker";

/**
 * Form validation component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
    // DatePicker,
    /*Switches,
    NumberInputSpinner,*/
  },
  page: {
    title: appConfig.approveAuditSale,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  data() {
    return {
      dismissSecs: 3,
      dismissCountDown: 0,
      resalert: "",
      Hide: false,
      enabled: false,
      submitform: false,
      submitformAddress: false,
      modalUpdateAddress: false,
      submitUpdateAddress: false,
      overlayFlag: false,
      title: "รายละเอียดตรวจสอบรูปส่งมอบรถ",
      items: [
        {
          text: "ตรวจมาตรฐาน",
          active: true,
        },
        {
          text: appConfig.approveAuditSale,
          href: "/branch-approve-auditSale",
        },
        {
          text: "รายละเอียดตรวจสอบรูปส่งมอบรถ",
          active: true,
        },
      ],
      filter: {
        branchId: "",
      },
      singleImage: false,
      auditId: this.$route.params.auditId,
      saleCode: "",
      bookingCode: "",
      customerNameTh: "",
      customerFamilyNameTh: "",
      status: "",
      grandTotalPrice: "",
      productNameTh: "",
      saleCondition: "",
      saleDate: "",
      vin: "",
      auditDetailId: "",
      ruleNameTh: "",
      createdBy: "",
      localDataBranch: [], //เป็น-array Id
      localData: [],
      value: null,
      value1: null,
      dataTest: [],
      options: [],
      imagePath: "",
      image: null,
      startIndex: "",
      endIndex: "",
      loading: undefined,
      total_pages: "",
      page: "",
      per_page: "",
      totalRecord: "",
      rowData: [], //? Data ที่ต่อ API
      // rowUser: [],
      rowAddress: [],
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25, 50, 100],
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      paginatedData: "",
      saleId: "",
      selectedUsers: [],
      isSelectedAll: false,
      detail: [],
      selectMode: "multi",
      selected: [],
      formApv: {
        status: "",
      },
      submitApv: false,
      statusOption: [
        {
          nameTh: "ผ่าน",
          id: "APV",
        },
        {
          nameTh: "ไม่ผ่าน",
          id: "NAPV",
        },
      ],
      fields: [
        {
          key: "index",
          sortable: true,
          label: "ลำดับ",
        },
        {
          key: "saleCode",
          sortable: true,
          label: "เลขที่การส่งมอบ/ขายรถ",
        },
        {
          key: "bookingCode",
          sortable: true,
          label: "เลขที่ใบจอง",
        },
        {
          key: "customerNameTh",
          sortable: true,
          label: "ชื่อลูกค้า",
        },
        {
          key: "customerFamilyNameTh",
          sortable: true,
          label: "นามสกุลลูกค้า",
        },
        {
          key: "productNameTh",
          sortable: true,
          label: "สินค้า",
        },
        {
          key: "grandTotalPrice",
          sortable: true,
          label: "ราคาสุทธิ",
        },
        {
          key: "saleCondition",
          sortable: true,
          label: "ประเภทการซื้อ",
        },
        {
          key: "saleDate",
          sortable: true,
          label: "วันที่ส่งมอบ/ขายรถ",
        },
        {
          key: "status",
          sortable: true,
          label: "สถานะ",
        },
        {
          key: "branchName",
          sortable: true,
          label: "สาขา",
        },
      ],

      error: "กรุณาตรวจสอบข้อมูลอีกครั้ง.",

      typeOption: [{ nameTh: "หลังการขาย", id: "SALE" }],
      form: {
        manageBranch: "",
        subjectCode: "",
        type: "",
        nameTh: "",
        nameEn: "",
      },
      provinceId: "",
      amphurId: "",
      districtId: "",
    };
  },
  validations: {
    form: {
      nameTh: {
        required,
      },
      type: {
        required,
      },
    },
    formApv: {
      status: {
        required,
      },
    },
  },
  mounted() {},
  computed: {
    fullAddress: function () {
      //

      return `${
        this.tooltipform.buildingNumber != ""
          ? "" + this.tooltipform.buildingNumber
          : this.tooltipform.buildingNumber
      } ${
        this.tooltipform.townshipNumber != ""
          ? "หมู่" + " " + this.tooltipform.townshipNumber
          : this.tooltipform.townshipNumber
      } ${
        this.tooltipform.townshipName != ""
          ? "" + this.tooltipform.townshipName
          : this.tooltipform.townshipName
      } ${
        this.tooltipform.subLane != ""
          ? "ตรอก" + " " + this.tooltipform.subLane
          : this.tooltipform.subLane
      } ${
        this.tooltipform.lane != ""
          ? "ซอย" + " " + this.tooltipform.lane
          : this.tooltipform.lane
      } ${
        this.tooltipform.streetName != "" &&
        this.tooltipform.streetName != undefined
          ? "ถนน" + " " + this.tooltipform.streetName
          : ""
      } ${
        this.districtId != "" &&
        this.districtId != undefined &&
        this.districtId != null &&
        this.districtId.nameTh != "" &&
        this.districtId.nameTh != undefined &&
        this.districtId.nameTh != null
          ? "ตำบล" + this.districtId.nameTh
          : ""
      } ${
        this.amphurId != "" &&
        this.amphurId != undefined &&
        this.amphurId != null &&
        this.amphurId.nameTh != "" &&
        this.amphurId.nameTh != undefined &&
        this.amphurId.nameTh != null
          ? "อำเภอ" + this.amphurId.nameTh
          : ""
      } ${
        this.provinceId != "" &&
        this.provinceId != null &&
        this.provinceId != undefined &&
        this.provinceId.nameTh != null &&
        this.provinceId.nameTh != "" &&
        this.provinceId.nameTh != undefined
          ? "จังหวัด" + this.provinceId.nameTh
          : ""
      }  ${
        this.tooltipform.postCode != ""
          ? "รหัสไปรษณีย์" + " " + this.tooltipform.postCode
          : ""
      }
      `;
    },
  },
  created() {
    this.getDataShow();
    // this.getLocalData();
  },
  methods: {
    formatDateTime(data) {
      var today = new Date(data);
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear() + 543;

      var time =
        ("0" + today.getHours()).slice(-2) +
        ":" +
        ("0" + today.getMinutes()).slice(-2) +
        ":" +
        ("0" + today.getSeconds()).slice(-2);

      const date = `${dd}/${mm}/${yyyy} ${time}`;
      return date;
      // console.log(dateString);
    },
    checkData(data) {
      return data != null ? "(" + data + ")" : "";
    },
    checkAppro(data) {
      return data != null ? "โดย:" + " " + data : "";
    },
    checkApproAt(data) {
      return data != null ? "เมื่อ:" + " " + this.formatDateTime(data) : "";
    },
    checkPath(data) {
      return data != null ? "ภาพตัวอย่าง" : "";
    },
    checkImg(data) {
      return data != null ? "โดย:" + " " + data : "";
    },
    checkNote(data) {
      if (data.updatedBy != null) {
        return data.note != null && data != null
          ? "โดย:" + " " + data.updatedBy
          : "";
      }
    },
    checkDate(data) {
      if (data.updatedAt != null) {
        return data.note != null
          ? "เมื่อ:" + " " + this.formatDateTime(data.updatedAt)
          : "";
      }
    },
    showModal(data) {
      this.auditDetailId = data.auditDetailId;
      this.ruleNameTh = data.ruleNameTh;
      this.$refs["modalUploadImg"].show(this.auditDetailId);
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert(response) {
      this.dismissCountDown = this.dismissSecs;
      this.resalert = response;
    },
    approveDetail(data, status) {
      useNetw
        .put("api/approve-audit-sale/rule/submit", {
          auditDetailId: data.auditDetailId,
          status: status,
        })
        .then((response) => {
          this.showAlert(response.data.message);

          this.getDataShow();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
    uploadRule: function (ruleId) {
      const bodyFormData = new FormData();
      this.overlayFlag = true;
      const imagefile = document.querySelector("#img");
      bodyFormData.append("auditDetailId", ruleId);

      bodyFormData.append(
        "imgFile",
        imagefile.files[0] ? imagefile.files[0] : ""
      );
      useNetw
        .post("api/approve-audit-sale/rule/upload", bodyFormData)
        .then((response) => {
          this.showAlert(response.data.message);
          this.$refs["modalUploadImg"].hide();
          this.getDataShow();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    FormSubmitAudit() {
      this.$v.$touch();
      this.submitApv = true;
      if (this.$v.formApv.$invalid != true) {
        this.submitAudit();
      }
    },
    submitAudit() {
      this.overlayFlag = true;

      useNetw
        .put("api/approve-audit-sale/submit", {
          auditId: atob(this.auditId),
          status: this.formApv.status.id,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$router.push({
            name: "branch-approve-auditSale",
          });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    showImg(fileId, index) {
      this.index = index;
      this.singleImage = true;
      this.getDataloadImage(fileId);
    },
    handleHide() {
      this.singleImage = false;
    },
    showImgAudit(fileId, index) {
      this.index = index;
      this.singleImage = true;
      this.getDataloadImageAudit(fileId);
    },
    handleHideAudit() {
      this.singleImage = false;
    },
    putRule: function (item) {
      this.overlayFlag = true;

      useNetw
        .put("api/approve-audit-sale/rule/update", {
          auditDetailId: item.auditDetailId,
          note: item.auditNote,
        })
        .then((response) => {
          this.showAlert(response.data.message);
          this.getDataShow();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    checksaleCondition(data) {
      let type;
      if (data == "C") {
        type = "เงินสด";
      } else if (data == "I") {
        type = "ผ่อนชำระ";
      } else if (data == "F") {
        type = "ไฟแนนซ์";
      }
      return type;
    },
    getDataloadImage: function (fileId) {
      this.overlayFlag = true;
      useNetw
        .get("api/approve-audit-sale/full-size-image", {
          params: {
            auditId: atob(this.auditId),
            fileId: fileId,
          },
        })
        .then((response) => {
          this.rowImg = response.data.data;
          this.imagePath = response.data.data.filePath;
          // this.test(map);

          // this.imagePath.splice(index, 1);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
          // this.loading = false; //skeleton false
        });
    },
    getDataloadImageAudit: function (data) {
      this.overlayFlag = true;

      // this.fileId = data.fileId;

      useNetw
        .get("api/audit-sale/full-size-image", {
          params: {
            auditId: atob(this.auditId),
            fileId: data,
          },
        })
        .then((response) => {
          this.rowImg = response.data.data;
          this.imagePath = response.data.data.filePath;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
          // this.loading = false; //skeleton false
        });
    },
    selectRow(data) {
      const dataArr = data[0];
      this.saleCode = dataArr.saleCode;
      this.filter.branchId = dataArr.branchName;
      this.customerNameTh = `${dataArr.customerNameTh} ${
        dataArr.customerFamilyNameTh !== null
          ? dataArr.customerFamilyNameTh
          : ""
      }`;
      this.createdBy = dataArr.createdBy;
      this.productNameTh = dataArr.productNameTh;
      this.vin = dataArr.vin;
      this.$refs.modalSale.hide();
    },
    select(data) {
      const dataArr = data[0];
      Swal.fire({
        title: `ต้องการสร้างใบขาย \n ${dataArr.saleCode} \n ใช่หรอไม่?`,
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "ตกลง",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.value) {
          this.submitSale(dataArr);
        }
      });
    },

    handleSearch() {
      if (this.filter.branchId === "") {
        this.localDataBranchId;
      } else {
        this.localDataBranchId = [this.filter.branchId.branchId];
      }
      this.getData();
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.getData();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getData();
    },
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;

      const localDataBranch = [];
      const localDataBranchId = [];
      // const localBranchIdItem ={};
      this.localData.forEach((item) => {
        localDataBranch.push(item), localDataBranchId.push(item.branchId);
      });
      this.localDataBranch = localDataBranch;
      this.localDataBranchId = localDataBranchId;
      this.getData();
      // console.log("สาขาาาาาา", this.localDataBranchId);
    },
    max() {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      const maxDate = new Date(today);

      maxDate.setDate(0);
      // console.log(maxDate);
    },
    ChangeNameTh(nameTh) {
      if (nameTh.nameTh === "นาย") {
        this.tooltipform.prefixIdEn = "Mr.";
      } else if (nameTh.nameTh === "นาง") {
        this.tooltipform.prefixIdEn = "Mrs.";
      } else {
        this.tooltipform.prefixIdEn = "Miss.";
      }
    },

    Form() {
      this.$v.$touch();
      this.submitform = true;
      if (this.$v.form.$invalid != true) {
        this.postData();
      }
    },

    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.rowAddress = filteredItems.length;
      this.currentPage = 1;
    },

    onRowSelected(rowData) {
      this.selected = rowData;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    getDataShow: function () {
      this.overlayFlag = true;
      // this.loading = true; //skeleton true
      useNetw
        .get("api/approve-audit-sale/show", {
          params: {
            auditId: atob(this.auditId),
          },
        })
        .then((response) => {
          this.saleCode = response.data.data.audit.saleCode;
          this.filter.branchId = response.data.data.audit.branchName;
          this.status = response.data.data.audit.status;
          this.customerNameTh = `${response.data.data.audit.customerNameTh} ${
            response.data.data.audit.customerFamilyNameTh
              ? response.data.data.audit.customerFamilyNameTh
              : ""
          }`;
          this.productNameTh = response.data.data.audit.productNameTh;
          this.createdBy = response.data.data.audit.createdBy;
          this.vin = response.data.data.audit.vin;
          this.detail = response.data.data.detail;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          this.overlayFlag = false;
          // this.loading = false; //skeleton false
        });
    },
    getData: function () {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/audit-sale/sale", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.localDataBranchId,
            // saleCode: this.filter.saleCode,
            // customer: this.filter.customer,
            // vin: this.filter.vin,
            // status: this.filter.status.id,
          },
        })
        .then((response) => {
          this.rowData = response.data.data;
          const per_Page = response.data.perPage;
          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;
          this.totalPage = Math.ceil(this.totalRecord / per_Page);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    postData: function () {
      this.overlayFlag = true;

      useNetw
        .post("api/d-audit-subject/store", {
          subjectCode: this.form.subjectCode,
          nameTh: this.form.nameTh,
          nameEn: this.form.nameEn,
          manageBranch: this.form.manageBranch,
          type: this.form.type.id,
        })
        .then((response) => {
          // this.getUser();
          //con
          this.cusIdPost = response.data.cusId;
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );

          this.$router.push({
            name: "edit-suditSubject",
            params: {
              subjectId: btoa(response.data.subjectId),
            },
          });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },

    clearAddressPost() {
      this.addressform.buildingNumber = "";
      this.addressform.townshipNumber = "";
      this.addressform.townshipName = "";
      this.addressform.subLane = "";
      this.addressform.lane = "";
      this.addressform.streetName = "";
      this.addressform.districtId = "";
      this.addressform.amphurId = "";
      this.addressform.provinceId = "";
      this.addressform.postCode = "";
      // this.fullAddress = "";
      this.submitformAddress = false;
    },
  },
};
</script>
<style scoped>
.input-placeholder {
  font-size: 12px;
}

@media only screen and (min-width: 500px) {
  .alert-fixed {
    position: fixed;
    top: 50px;
    left: 65%;
    width: 30%;
    z-index: 9999;
    border-radius: 0px;
  }
  .width-tb {
    height: 150px;
    /* width: 150px; */
  }
  .preview-img {
    max-width: auto;
    max-height: 150px;
  }
  td {
    width: 100px;
  }
}
</style>
